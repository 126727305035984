import { all } from 'redux-saga/effects';
import authSagas from './Auth.js';
import nft from './NFT.js';

export default function* rootSaga() {
  yield all([
    authSagas(),
    nft()
  ]);
}

