import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setTopCategories, setCategoryNFT, setCategories, setTypesNFT, setProfileFromApi, setSingleNFT, setToggleHeart, setHotBids, setCollectionData } from '../actions/NFT';

function* createNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/postnft`, payload: payload });
  if (response) EventBus.publish("success", response['data']['message'])
  else EventBus.publish("error", error['data']['message'])
};

function* getCategoryNFT({ payload }) {
  if (payload == 'all') {
    const { error, response } = yield call(getCall, `/nft/getall/`);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) yield put(setCategoryNFT(response['data']['body']));
  } else {
    const { error, response } = yield call(getCall, `/nft/getNftbyCate/${payload}`);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) yield put(setCategoryNFT(response['data']['body']));
  }
};

function* getTypesNFT() {
  const { error, response } = yield call(getCall, `/nft/getall/`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setTypesNFT(response['data']['body']));
}

function* getUsersProfile({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getSingle/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setProfileFromApi(response['data']['body']));
};

function* getTopCategories() {
  const { error, response } = yield call(getCall, `/nft/topCategories`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setTopCategories(response['data']['body']));
};

function* getSingleNFT({ payload }) {
  const { error, response } = yield call(getCall, `nft/getSingle/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setSingleNFT(response['data']['body']));
};

function* toggleHeart({ payload }) {
  const { error, response } = yield call(putCall, { path: `/nft/likeNft`, payload });
  if (response) {
    yield put(setToggleHeart(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  }
  else EventBus.publish("error", error['data']['message'])
};

function* hotBidsData() {
  const { error, response } = yield call(getCall, `/nft/hotBids/`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setHotBids(response['data']['body']));
};

function* createCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/collectionCreate`, payload: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setCollectionData(response['data']['body']));
};

function* getCollectionData({ payload }) {
  const { error, response } = yield call(postCall, { path: `/nft/collectionGet`, payload: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else yield put(setCollectionData(response['data']['body']));
};

function* actionWatcher() {
  yield takeEvery('CREATE_NFT', createNFT);
  yield takeEvery('TOGGLE_HEART', toggleHeart);
  yield takeEvery('HOT_BIDS_DATA', hotBidsData);
  yield takeEvery('GET_TYPES_NFT', getTypesNFT);
  yield takeEvery('GET_SINGLE_NFT', getSingleNFT);
  yield takeEvery('GET_CATEGORY_NFT', getCategoryNFT);
  yield takeEvery('SET_USER_PROFILE', getUsersProfile);
  yield takeEvery('CREATE_COLLECTION', createCollection);
  yield takeEvery('GET_TOP_CATEGORIES', getTopCategories);
  yield takeEvery('GET_COLLECTION_DATA', getCollectionData)
}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}