import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setAddress, setUserData, setUpdateUserProfile } from '../actions/Auth';


function* login({ payload }) {
  try {
    const { error, response } = yield call(postCall, { path: `/users/login`, payload: payload });
    if (response) {
      if (response) {
        yield put(setAddress(payload['publicAddress']));
        EventBus.publish("success", response['data']['message']);
      }
      else EventBus.publish("error", error['data']['message'])
    }
  }
  catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
};

function* updateUserProfile({ payload }) {
  try {
    const { error, response } = yield call(putCallWithFile, { path: `/users/update`, payload: payload });
    if (response) {
      yield put(setUpdateUserProfile(response));
      EventBus.publish("success", response['data']['message'])
    } else {
      EventBus.publish("error", error['data']['message'])
    }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
}


function* getUserData({ payload }) {
  try {
    const { error, response } = yield call(getCall, `/users/getUser/${payload}`);
    if (response) {
      yield put(setUserData(response['data']['body']));
    } else {
      EventBus.publish("error", error['data']['message'])
    }
  } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
};



function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('UPDATE_USER_PROFILE', updateUserProfile);
  yield takeEvery('GET_USER_DATA', getUserData);

}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}


function putCallWithFile({ path, payload }) {
  const formData = new FormData();
  formData.append('avatar', payload.file);
  formData.append('bio', payload.bio);
  formData.append('email', payload.email);
  formData.append('name', payload.name);
  formData.append('portfolio', payload.portfolio);
  formData.append('publicaddress', payload.publicaddress);
  formData.append('twittername', payload.twittername);
  formData.append('url', payload.url);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .put(path, formData, config)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
