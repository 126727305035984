import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import Marquee from "react-fast-marquee";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { web3 } from '../../store/web3';
import { networkId } from '../../store/config';
import { getUserData } from '../../store/actions/Auth';
import { login, isLoggedIn, logout, toggleConnectModal, toggleSwitchModal } from '../../store/actions/Auth';

import './index.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNetwork: false,
            isMetaMask: false,
            publicAddress: localStorage.getItem('publicAddress'),
        };
    };

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            this.setState({ isMetaMask: !this.state.isMetaMask });
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                this.setState({ isNetwork: !this.state.isNetwork });
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.isLoggedIn(true);
            this.props.login({ publicAddress: address, type: 'login' });
        });
    };

    logoutWallet = () => {
        this.props.logout();
        this.setState({ isLogout: !this.state.isLogout, isPhysicalAddress: false, publicAddress: '' });
    };

    componentDidUpdate(prevProps, prevState) {
        let paddress = localStorage.getItem('publicAddress');
        if (prevProps.address !== this.props.address) {
            if (this.props.address === paddress)
                this.setState({ isPhysicalAddress: true, publicAddress: paddress });
            else this.setState({ isPhysicalAddress: false });
        }
    };

    copied = () => EventBus.publish('success', 'Wallet Address Copied');
    toggleNetworkModal = () => this.setState({ isNetwork: !this.state.isNetwork });
    toggleMetaMaskModal = () => this.setState({ isMetaMask: !this.state.isMetaMask });

    render() {
        let { address, userprofileData, sticky } = this.props;
        let { isMetaMask, isNetwork, publicAddress } = this.state;
        return (
            <div className={`main-header ${sticky && 'sticky-nav'}`}>
                <nav className='navbar navbar-expand-lg'>
                    <div className='auto-container'>
                        <div className='inner-container'>
                            <Link className='navbar-brand' href='#'><img src={require('../../static/images/landing/logo.png')} alt='' /></Link>

                            <button className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation'>
                                <i class='fa fa-bars ' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                {/* <div className='searchContainer'>
                                <i class='fa fa-search' aria-hidden='true'></i>
                                <input className='searchBox' type='search' name='search' placeholder='Search by creator, collectible or collection' />
                            </div> */}
                                <ul className='navbar-nav ml-auto'>
                                    <li className='nav-item active'>
                                        <HashLink className='nav-link pr-lg-4' smooth to='/'>Home</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link  pr-lg-4' to='#' >Build</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link  pr-lg-4' smooth to='#'>Network</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link  pr-lg-4' to='#' >Community</Link>
                                    </li>
                                </ul>

                                <div className='social-links'>
                                    <ul className='banner-social'>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/twitter.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/telegram.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/reddit.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/discord.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/github.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/youtube.png')} alt='' /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" className='sociallink'><img src={require('../../static/images/landing/medium.png')} alt='' /></Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* ------------------METAMASK MODAL----------------- */}
                                <Modal isOpen={isMetaMask} toggle={this.toggleMetaMaskModal} className='main-modal metamask-modal'>
                                    <ModalHeader toggle={this.toggleMetaMaskModal}>
                                        <div className='modal-logo'>
                                            <img src={require('../../static/images/landing/logo.png')} alt='' />
                                        </div>
                                    </ModalHeader>
                                    <ModalBody className='modal-body' >
                                        <div className='container modal-upper-pad'>
                                            <div className='text-center form-modal-landing-nft'>
                                                <div className='message-text'><p>Please Install Metamask!!!</p></div>
                                            </div>

                                        </div>
                                    </ModalBody>
                                </Modal>

                                {/* ------------------NETWORK MODAL----------------- */}
                                <Modal isOpen={isNetwork} toggle={this.toggleNetworkModal} className='main-modal metamask-modal'>
                                    <ModalHeader toggle={this.toggleNetworkModal}>
                                        <div className='modal-logo'>
                                            <img src={require('../../static/images/landing/logo.png')} alt='' />
                                        </div>
                                    </ModalHeader>
                                    <ModalBody className='modal-body' >
                                        <div className='container modal-upper-pad'>
                                            <div className='text-center form-modal-landing-nft'>
                                                <div className='message-text'><p>Please Change Your Network to Rinkeby Test Network</p></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </nav>


                <div className='marquee-area'>
                    <Marquee>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo dignissim finibus. Aenean sed feugiat turpis. Etiam dapibus congue justo ut tempor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo dignissim finibus. Aenean sed feugiat turpis. Etiam dapibus congue justo ut tempor.
                    </Marquee>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = { login, isLoggedIn, logout, toggleConnectModal, toggleSwitchModal, getUserData };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, isSignup, isSignupInfo, address, isPolicyModal, loginToken, setAddress, userprofileData } = Auth;
    return { isLogin, isSignup, isSignupInfo, address, isPolicyModal, userprofileData }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);