import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core';
import { Send, CheckCircle, Info, Error, Done, Facebook, Twitter, Instagram, LinkedIn, LiveTvRounded } from '@material-ui/icons';

import './index.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { activity, sliderone, slidertwo } from './recentActivity';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  };

  render() {

    const owl_option = {
      margin: 30,
      nav: false,
      loop: true,
      infinite: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1100: {
          items: 3,
        }
      },
    };



    return (
      <div className='landing-nft'>

        <Navbar />
        {/* Haeder Section */}
        <section className="header-section" style={{ backgroundImage: `url(${require("../../static/images/landing/banner-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="textheader-main">
                  <h5>Spend, Save, and Stake Like Never Before</h5>
                  <h1>Programmable Money For The Internet</h1>
                  <p>ST is a public blockchain protocol deploying a suite of algorithmic decentralized stablecoins which underpin a thriving ecosystem that brings DeFi to the masses.</p>
                  <button type="button" className="btn-style-one">Get Started <i className='icon fa fa-angle-right'></i></button>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12 col-12">
                <div className="imageback-header ">
                  <div dangerouslySetInnerHTML={{
                    __html: `
                        <video autoPlay="true" muted="true" preload="auto" webkit-playsinline="true" playsinline="true">
                            <source src="./banner-video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    `}}></div>
                </div>
              </div> */}
            </div>
          </div>

        </section>

        <section className="pick-design" >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="inner-text">
                  <div className="sec-title">
                    <h5>Join the vibrant ST ecosystem</h5>
                    <h2>And #SOFTTIK Technologies community.</h2>
                  </div>
                  <div className="text-pick-main">
                    <p>ST’s ecosystem is booming. Join the creators, builders, and users of the Terra ecosystem to pioneer the future of DeFi & online communities.</p>
                    <button type="button" className="btn-style-one">Build on ST <i className='icon fa fa-angle-right'></i></button>
                    <button type="button" className="btn-style-two">Discover the ST ecosystem <i className='icon fa fa-angle-right'></i></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="image-pick">
                  <img className="about-gif" src={require("../../static/images/landing/about-img.png")} alt="" />
                  <img className="back-img" src={require("../../static/images/landing/about-back.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="defi-service" style={{ backgroundImage: `url(${require("../../static/images/landing/defi-servie.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="defi-service-block col-lg-6 col-md-12">
                <div className="defi-service-inner">

                  <div className="text-box">
                    <h3>Industry-Leading Decentralized <br /> Stablecoin, <span className="theme-color">$UST</span></h3>
                  </div>
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/icon-1.png")} alt="" />
                  </div>
                </div>
              </div>
              <div className="defi-service-block col-lg-6 col-md-12">
                <div className="defi-service-inner style-two">

                  <div className="text-box">
                    <h3><span className="theme-color">Vibrant</span> Smart Contracts <br />Platform</h3>
                  </div>
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/icon-2.png")} alt="" />
                  </div>
                </div>
              </div>

              <div className="defi-service-sperate col-lg-12 col-md-12">
                <div className="defi-service-sperate">
                  <img src={require("../../static/images/landing/defiservice-column.png")} alt="" />
                </div>
              </div>

              <div className="defi-service-block col-lg-6 col-md-12">
                <div className="defi-service-inner">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/icon-3.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h3>Thriving Cross-Chain <span className="theme-color">DeFi </span> <br /> Environment</h3>
                  </div>

                </div>
              </div>
              <div className="defi-service-block col-lg-6 col-md-12">
                <div className="defi-service-inner style-two">
                  <div className="icon-box">
                    <img src={require("../../static/images/landing/icon-4.png")} alt="" />
                  </div>
                  <div className="text-box">
                    <h3>Built on the <span className="theme-color">Cosmos SDK &</span> <br /><span className="theme-color">Tendermint </span>Consensus</h3>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="video-area">
            <div className="container-fluid">
              <div className="row">
                <div className="video-img-block col-lg-6 col-md-12">
                  <div className="video-inner">
                    <div className="image-box">
                      <img src={require("../../static/images/landing/video-sec-img.png")} alt="" />
                      <div className="icon-video">
                        <Link to="#"><img src={require("../../static/images/landing/video-icon.png")} alt="" /></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="video-text-block col-lg-6 col-md-12">
                  <div className="text-inner">
                    <div className="sec-title">
                      <h5>ST documentary</h5>
                      <h2>Watch the ST<br /> documentary about<br /> the decentralized <br /> money at the heart <br />of an open financial<br /> ecosystem</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blockchain-sec">
            <div className="auto-container">
              <div className="row">
                <div className="col-12">
                  <div className="sec-title text-center">
                    <h5>ST  is the leading cross-chain protocol in the industry</h5>
                    <h2>With ST assets supported by leading blockchains.</h2>
                    <button type="button" className="btn-style-one">Visit ST bridge <i className='icon fa fa-angle-right'></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid blockchain-imge-sec">
              <div className="row">
                <div className="col-12">
                  <img src={require("../../static/images/landing/blockchain-img.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="suported-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/suported-bg.png")})` }}>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="sec-title">
                  <h5>Supported by the unrivaled #LUNAtics community</h5>
                  <h2>Join the fastest-growing ecosystem in crypto with 100+ projects across DeFi, Web3, and NFTs</h2>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="box-area">
                  <div className="sportted-box">
                    <div className="sportted-inner">
                      <div className="icon-box">
                        <i className='icon fa fa-angle-right'></i>
                      </div>
                      <div className="text-box">
                        <h4>Learn more about  ST</h4>
                        <p>Discover  how ST  works  and how  you can interact with  the  ecosystem.</p>
                      </div>
                    </div>
                  </div>

                  <div className="sportted-box">
                    <div className="sportted-inner">
                      <div className="icon-box">
                        <i className='icon fa fa-angle-right'></i>
                      </div>
                      <div className="text-box">
                        <h4>Build on ST</h4>
                        <p>DBuild brand new apps and protocols or contribute to existing  ST codebase.</p>
                      </div>
                    </div>
                  </div>

                  <div className="sportted-box">
                    <div className="sportted-inner">
                      <div className="icon-box">
                        <i className='icon fa fa-angle-right'></i>
                      </div>
                      <div className="text-box">
                        <h4>Become a validator </h4>
                        <p>Help secure the network and earn rewards</p>
                      </div>
                    </div>
                  </div>

                  <div className="sportted-box">
                    <div className="sportted-inner">
                      <div className="icon-box">
                        <i className='icon fa fa-angle-right'></i>
                      </div>
                      <div className="text-box">
                        <h4>Join the community </h4>
                        <p>Join crypto's most vibrant community, the #LUNAtics, shape the future  of ST</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="stakeholders-sec">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <div className="sec-title">
                  <h2>The ST Protocol is an open-source stablecoin network controlled by its stakeholders</h2>
                  <h5>More demand for ST stablecoins (UST) = more value capture by LUNA - ST’s decentralized reserve asset.</h5>
                  <p>Access the first successful, decentralized algorithmic stablecoin. Participate in the value creation of a new DeFi stack.</p>
                  <button type="button" className="btn-style-one">ST & luna ecosystem<i className='icon fa fa-angle-right'></i></button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="media-sec">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-12 col-12 text-center">
                <div className="sec-title">
                  <h2>Terra in the media</h2>
                </div>
                <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option}>
                  <div class="item">
                    <div className="media-box">
                      <div className="icon-area">
                        <img src={require("../../static/images/landing/media-1.png")} alt="" />
                      </div>
                      <div className="textbox">
                        <h3>Tech Crunch</h3>
                        <p>Crypto investors like STforms Labs so much, they're committing $150 million to it's ecosystem</p>
                      </div>
                    </div>
                  </div>


                  <div class="item">
                    <div className="media-box">
                      <div className="icon-area">
                        <img src={require("../../static/images/landing/media-2.png")} alt="" />
                      </div>
                      <div className="textbox">
                        <h3>Yahoo! Finance</h3>
                        <p>STform labs Assembles an All-star roster of investors committed to Deploying million in the ST ecosystem</p>
                      </div>
                    </div>

                  </div>
                  <div class="item">
                    <div className="media-box">
                      <div className="icon-area">
                        <img src={require("../../static/images/landing/media-3.png")} alt="" />
                      </div>
                      <div className="textbox">
                        <h3>Bloomberg</h3>
                        <p>Do Kwon explains synthetic equities trading on blockchain</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <Footer />

      </div>
    );
  }
}


const mapDispatchToProps = {
};

const mapStateToProps = ({ }) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);