export const getCategoryNFT = (data) => ({
  type: 'GET_CATEGORY_NFT',
  payload: data,
});

export const setCategoryNFT = (data) => ({
  type: 'SET_CATEGORY_NFT',
  payload: data,
});

export const getTopCategories = () => ({
  type: 'GET_TOP_CATEGORIES',
});

export const setTopCategories = (data) => ({
  type: 'SET_TOP_CATEGORIES',
  payload: data,
});

export const getTypesNFT = () => ({
  type: 'GET_TYPES_NFT',
});

export const setTypesNFT = (data) => ({
  type: 'SET_TYPES_NFT',
  payload: data,
});

export const setCategories = (data) => ({
  type: 'SET_CATEGORIES',
  payload: data,
});

export const getSingleNFT = (data) => ({
  type: 'GET_SINGLE_NFT',
  payload: data,
});

export const setSingleNFT = (data) => ({
  type: 'SET_SINGLE_NFT',
  payload: data,
});

export const toggleHeart = (data) => ({
  type: 'TOGGLE_HEART',
  payload: data,
});

export const setToggleHeart = (data) => ({
  type: 'GET_ISLIKED',
  payload: data,
})

export const setProfile = (data) => ({
  type: 'SET_USER_PROFILE',
  payload: data,
});

export const setProfileFromApi = (data) => ({
  type: 'SET_USER_PROFILE_FROM_API',
  payload: data,
});

export const CreateNFT = (data) => ({
  type: 'CREATE_NFT',
  payload: data,
});

export const SetLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

export const SetToken = (data) => ({
  type: 'SET_TOKEN',
  payload: data,
});

export const setOrderCreate = (data) => ({
  type: 'CREATE_ORDER_API',
  payload: data,
});

/* Get Collection Data */
export const getCollectionData = (data) => ({
  type: 'GET_COLLECTION_DATA',
  payload: data,
});

/* All Collections */
export const setCollectionData = (data) => ({
  type: 'SET_COLLECTION_DATA',
  payload: data,
});

export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data,
});

/*  */
export const selectCollection = (data) => ({
  type: 'SELECT_COLLECTION',
  payload: data,
});

export const hotBidsAction = (data) => ({
  type: 'HOT_BIDS_DATA',
})

export const setHotBids = (data) => ({
  type: 'SET_HOT_BIDS_DATA',
  payload: data
})
