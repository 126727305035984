import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Landing from './landing';
// import Profile from './profile';
// import Edit from './editprofile';
// import Category from './category';
// import Activity from './activity';
// import SingleNFT from './singleNFT';
import Loader from '../components/loader';
// import CreateSingle from './createSingle';
// import PersonalProfile from './userprofile';
// import PrivateRoute from '../store/PrivateRoute';

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import 'font-awesome/css/font-awesome.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { web3 } from "../store/web3";
import { logout } from '../store/actions/Auth';
import { networkId, message } from "../store/config";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    this.handleWalletChanges();
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on("tokenExpired", () => this.props.logout());
  };

  handleWalletChanges = () => {
    let { auth } = this.props;
    if (typeof window.ethereum !== "undefined") {
      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) EventBus.publish('info', message);
      });

      // if (window.ethereum.currentProvider.isMetaMask) {
      window.ethereum.on("accountsChanged", accounts => {
        this.props.logout();
        // if (!auth || auth == '' || auth == null || auth == undefined) window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        if (netId != networkId) {
          this.props.logout();
          // window.location.reload();
          EventBus.publish("info", "Network has been changed");
        }
      });
    }
  };

  render() {

    return (
      <div>
        <Loader />
        <ToastContainer closeOnClick position="bottom-left" />
        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <Landing {...props} />} />
            <Route exact path='/landing' component={props => <Landing {...props} />} />
            {/* <Route exact path='/category' component={props => <Category {...props} />} />
            <Route exact path='/activity' component={props => <Activity {...props} />} />
            <PrivateRoute exact path='/userprofile/:id' component={props => <Profile {...props} />} />
            <PrivateRoute exact path='/profile/:id' component={props => <PersonalProfile {...props} />} />
            <PrivateRoute exact path='/editprofile' component={props => <Edit {...props} />} />
            <PrivateRoute exact path='/singleNFT/:id' component={props => <SingleNFT {...props} />} /> */}
            {/* <PrivateRoute exact path='/singleNFT?id=' component={props => <SingleNFT {...props} />} /> */}
            {/* <PrivateRoute exact path='/createSingle' component={props => <CreateSingle {...props} />} /> */}
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = { logout, };

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);