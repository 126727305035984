let initialState = {
  token: '',
  types: [],
  getNft: [],
  hotBids: {},
  profiles: [],
  isToggle: {},
  singleNFT: {},
  allNFTData: [],
  categories: [],
  topCategories: [],
  collectionData: {},
  allCollections: [],
  selectedCollection: {},
  setLoader: { message: 'Please wait...', status: false },
}

const NFT = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'SET_CATEGORY_NFT':
      return {
        ...state,
        allNFTData: payload
      };

    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: payload
      };

    case 'SET_TOP_CATEGORIES':
      return {
        ...state,
        topCategories: payload
      };

    case 'SET_TYPES_NFT':
      return {
        ...state,
        types: payload
      };

    case 'SET_SINGLE_NFT':
      return {
        ...state,
        singleNFT: payload
      };

    case 'SET_USER_PROFILE_FROM_API':
      return {
        ...state,
        profiles: payload
      };

    case 'SET_CREATE_NFT':
      return {
        ...state,
        getNft: payload
      };

    case 'SET_LOADER':
      return {
        ...state,
        setLoader: payload
      };

    case 'SET_TOKEN':
      return {
        ...state,
        token: payload
      };

    case 'CREATE_ORDER_API':
      return {
        ...state,
        token: payload
      };

    case 'GET_ISLIKED':
      return {
        ...state,
        isToggle: payload
      };

    case 'SET_HOT_BIDS_DATA':
      return {
        ...state,
        hotBids: payload
      }

    /* These are all user collections */
    case 'SET_COLLECTION_DATA':
      return {
        ...state,
        categories: payload['categories'],
        allCollections: payload['collections'],
      }

    /* This is to select a collection */
    case 'SELECT_COLLECTION':
      return {
        ...state,
        selectedCollection: payload
      }

    default:
      return state;
  }
};
export default NFT;